/* Mixins */
.light-index-component {
  min-width: 1236px !important;
}
.light-index-component .page-body-margin {
  margin: 16px;
}
.light-index-component .energy-electricity-item-interval-type-switch {
  position: absolute;
  top: 50px;
  right: 20px;
}
.light-index-component .parking-gauge-chart .parking-gauge-chart-content {
  height: 400px;
  background-color: #fff;
}
.light-index-component .parking-gauge-chart .parking-gauge-chart-content .parking-title {
  padding-right: 8px;
  border-right: solid 1px #EDEEF0;
  font-size: 14px;
  font-weight: bold;
}
.light-index-component .parking-gauge-chart .parking-gauge-chart-content .parking-count {
  font-size: 14px;
  font-weight: bold;
}
.light-index-component .parking-gauge-chart .parking-gauge-chart-content .parking-desc {
  font-size: 12px;
}
.light-index-component .parking-gauge-chart .parking-gauge-chart-content .parking-gauge-chart-text-wrapper {
  width: 160px;
  margin: 24px auto 0 auto;
}